<template>
<div>
  <div class="container-fluid header-sub " style="">
    <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
  </div>
  <div>
    <div class="container-standar text-center ">
      <div class="dk-container card shadow">
        <h1 class="d-block font-30 text-primary  mt-10 font-weight-7 ">TENTANG KAMI REAL TRAVEL</h1>
        <div class="ff-doa mt-2 mb-10 pad-50 pad-mobile-20">
          <div class="row">
            <div class="col-md-6 text-left">
              <span
                class="d-inline-block font-24 font-mobile-18 font-weight-7 text-primary width-100"
              >PT Pandi Kencana Murni</span>
              
              <p class="font-weight-5 mt-2 font-16 font-mobile-14">
                <span
                class="d-inline-block text-grey font-14 font-mobile-12"
              >PT. Pandi Kencana Murni atau Real Travel</span> Berdiri Sejak Tahun 1996 dan didirikan oleh Bapak H. Hamimzar Arif, S.Sos Selaku CEO. Pakem Tours Memiliki Izin Haji : PHU/PIHK 3154/VIII/2011 Nomor : U-142 TAHUN 2020 dan Izin Umrah, SK No: 684 Tahun 2020 (Perpanjangan) Serta Memiliki Izin Kepariwisataan Dan Masuk Sebagai Anggota Asita, Anggota Himpunan Penyelenggara Umroh dan Haji ( Himpuh ). Pakem Tours Telah Bekerja sama dengan Seluruh Airline / Penerbangan.  Telah Berpengalaman Memberangkatkan Puluhan ribu Jamaah Haji, Umroh dan Tour. Sehingga Mendapatkan penghargaan dari Kementerian Pariwisata dan Kementerian Agama Sebagai “ Best Excellent Service” Travel Penyelenggara Haji dan Umroh.
                <br />
                <br /><br>
                <strong class="text-primary">Ijin Haji SK No : 684 Tahun 2020</strong>
                <br />
                <br />
                <strong class="text-primary">Ijin Umrah SK No : U-142 TAHUN 2020</strong>
              </p>
            </div>
            <div class="col-md-6 text-center">
              <img
                class="margin-auto width-80"
                src="https://i0.wp.com/pakemtours.co.id/wp-content/uploads/real-real-logo.jpg"
                alt
              />
            </div>
            <div class="col-md-12 mt-20">
              <div class="tentangkami">
                <span
                  class="font-24 font-mobile-20 font-weight-7 mb-40 text-primary d-inline-block width-100"
                >PENGHARGAAN TERBAIK</span>
                <div class="row">
                  <div class="col-md-6 text-center">
                    <img src="@/assets/images/Awards-1.jpg" alt class="margin-auto"/>
                  </div>
                  <div class="col-md-6 text-center">
                    <img src="@/assets/images/Awards-2.jpg" alt class="margin-auto"/>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-md-6 text-center">
                    <img src="@/assets/images/Awards-3.jpg" alt class="margin-auto"/>
                  </div>
                  <div class="col-md-6 text-center">
                    <img src="@/assets/images/Awards-4.jpg" alt class="margin-auto"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-20">
              <div class="tentangkami">
                <span
                  class="font-24 font-mobile-20 font-weight-7 text-primary d-inline-block width-100"
                >KANTOR PUSAT</span>
                <p class="mt-2 font-weight-5 font-mobile-14">
                  <a href="/cabang">Jakarta, Rukan Bisnis Park Kirana Cawang, Jl.DI Pandjaitan Kav. 48 Blok A-5 Kebonnanas
                  <br />Cipinang Cempedak-Jatinegara-Jakarta Timur No.telp 021-29067433</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-22 mb-40">
            <span
              class="font-24 font-weight-7 font-mobile-16 text-primary d-inline-block width-100"
            >KANTOR CABANG</span>
          </div>
          <div class="col-md-12 mb-40 ">
            <img class="width-80 margin-auto" src="@/assets/images/background/peta.jpg" alt />
          </div>
        </div>
        <div class="container-fluid ">
          <div class="row">
            <div class="col-md-6 font-weight-5 font-16 pad-40 font-mobile-12 text-left">
              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/makassar"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Makassar</b> : Jl. Toddpuli Raya No. 64 B Makassar No Telp. 0411-4664110</a>
              
              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="cabang/maros"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Maros</b> : Jl. Poros Makassar-Maros Bulubulu Kab. Maros No Telp. 0411-551462</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/pekanbaru"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Pekanbaru</b> : JL.Paus No.104C Pekanbaru Riau No Telp. 0761-23674</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/bandung"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Bandung</b> : Jl. Metro indah mall blok B-26 No Telp. +62 895-1394-2771</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/banten"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Banten</b> : Jl. Kagungan No. 5 Kaloran, Lontar Baru, Kota Serang Banten No Telp. 0254-223869</a>

            </div>
            <div class="col-md-6 font-weight-5 font-16 pad-40 font-mobile-12 text-left">
              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/jepara"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Jepara</b> : Jl. Tahunan-Batealit Jepara, Jawa Tengah No Telp. 085740614355</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/palembang"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Palembang</b> : Komplek kelapa Gading Center no 1 H KM 9,5 Sukarame. No Telp. 0711–5611061</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/lampung"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Lampung</b> : Jl. ZA Pagar Alam, Komplek Rajabasa Bandar Lampung No.1 No Telp. 0721-781202</a>

              <a class="btn mb-4 btn-light btn-block btn-md text-left" href="/cabang/polewali"><i class="fa fa-map-marker text-primary"></i> <b class="text-primary">Polewali Mandar</b> : Jl. H. Andi Depu (Depan SMA Neg 1) Polewali. No Telp. 0428-2420084</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", true);
  }
};
</script>